
import React from 'react';
import FrequentQuections from "../FrequentQuections";
import ServiceMain from "../ServiceMain";
import Counter from "../Counter";
import Title from "../Title";
import Benefits from "../Benefits";
import VisionAndMission from "../VisionAndMission";
import CoreValues from "../CoreValues";

const About = () => {
    return (
        <>

            <ServiceMain img={"/our-img.png"}/>

            <VisionAndMission/>
            <Counter/>

            <Title title2={"Our Core Values"}/>


            <CoreValues/>

            <Title title2="Our Engagement Models" title3="We make it easy to collaborate with us by offering a range of flexible engagement models. Choose the one that best fits the needs of your project"/>
            <Benefits/>

            <FrequentQuections  icon="/faq/aboutpage.png"/>
        </>
    )
}
export default About;