
import React from 'react';
import FrequentQuections from "../FrequentQuections";
import ServiceMain from "../ServiceMain";
import IntroductionSection from "../IntroductionSection";
import Counter from "../Counter";
import Title from "../Title";
import Benefits from "../Benefits";
import Home2Des from "../Home2Des";

const Services = () => {
    return (
        <>

            <ServiceMain img={"/our-services/service_home.png"}/>
            <IntroductionSection left={"Customized Software Solutions Tailored to Your Business"}
                                 isFeature={false}
                                 rightDes={"At our core, we believe that every business is unique, and so are its challenges. That’s why we specialize in creating bespoke software solutions designed to meet the specific needs of your brand. As a leading software development company in Sri Lanka, we’re committed to delivering innovative, sustainable, and practical solutions to help your business thrive in today’s fast-paced digital world.\n" +
                                     "Many businesses underestimate the power of customized software, leaving them unable to tackle modern challenges effectively. We’re here to change that. Whether you’re a start-up or a multinational corporation, our team takes the time to understand your industry and identify the areas where the right software can make the biggest impact.\n" +
                                     "From streamlining operations to achieving key objectives, we craft software that’s not only functional but also aligns perfectly with your goals. Our process is simple yet effective—driven by strategy, innovation, and a focus on delivering real results.\n" +
                                     "Let us partner with you to create software that transforms the way you do business. Explore how our expertise can help you overcome challenges and unlock new opportunities. Your success is our priority!\n"}
                                 />

            <Title title2="Services for Your Business Growth"/>

            <Home2Des itemList={
                {
                    items: [
                        {
                            icon: "/Custom Web App Development.png",
                            iconText: "Custom Web App Development",
                            description: "Unlock powerful web solutions tailored to your needs with our expert custom web app development services.",
                        },
                        {
                            icon: "/Wordpress Development.png",
                            iconText: "Wordpress Development",
                            description: "Transform your ideas into a dynamic, fully customizable WordPress site built to enhance your online presence.",
                        },
                        {
                            icon: "/E-Commerce Development.png",
                            iconText: "E-Commerce Development",
                            description: "Boost your eCommerce platform with custom integrations, automation, and personalized features for optimized performance.",
                        },
                        {
                            icon: "/Business Process Automation.png",
                            iconText: "Business Process Automation",
                            description: "Streamline daily tasks with automated solutions crafted to make your business more efficient and productive.",
                        },
                        {
                            icon: "/Custom Business Portals.png",
                            iconText: "Custom Business Portals",
                            description: "Build a custom portal designed to meet your unique business requirements and goals, enhancing connectivity and collaboration.",
                        },
                        {
                            icon: "/Consultancy & Discovery.png",
                            iconText: "Consultancy & Discovery",
                            description: "Gain valuable insights and strategic guidance to help your business adopt the right technologies and make impactful decisions.",
                        },
                        {
                            icon: "/Maintenance & Supports.png",
                            iconText: "Maintenance & Supports",
                            description: "Count on our reliable maintenance and support to keep your business software running smoothly for long-term success.",
                        }
                    ]
                }
            }
            />


            <Counter/>

            <Title title2="Small Business Branding, Web Design, and Marketing"
            />

            <Home2Des itemList={
                {
                    items: [
                        {
                            icon: "/Custom Web App Development.png",
                            iconText: "Logo Design",
                            description: "Crafting a unique and distinctive logo that embodies the core values and personality of your business, while creating a lasting impression.",
                        },
                        {
                            icon: "/Wordpress Development.png",
                            iconText: "Web Design",
                            description: "Developing user-friendly, aesthetically pleasing websites optimized for SEO, providing an engaging online experience for your visitors.",
                        },
                        {
                            icon: "/E-Commerce Development.png",
                            iconText: "Branding",
                            description: "Building powerful brand identities that form a deep connection with your target audience and differentiate you in the marketplace.",
                        },
                        {
                            icon: "/Business Process Automation.png",
                            iconText: "Graphic Design",
                            description: "Creating visually striking designs that captive and communicate your message effectively, to enhance your brand’s visual appeal.",
                        },
                        {
                            icon: "/Custom Business Portals.png",
                            iconText: "Social Media",
                            description: "Implementing dynamic social media strategies that elevate your online presence, engage your audience, and boost your brand’s digital footprint.",
                        },
                        {
                            icon: "/Consultancy & Discovery.png",
                            iconText: "Marketing",
                            description: "Delivering innovative marketing solutions that drive growth, increase brand awareness, and generate meaningful connections with your customers.",
                        }
                    ]
                }
            }
            />

            <FrequentQuections  icon="/faq/servicepage.png"/>
        </>
    )
}
export default Services;