import React from 'react';
import './introductionSection.css'


const IntroductionSection = ({isFeature,left,rightTitle,rightDes}) => {
    return (
        <>

            <div className="introduction-section">
                <div className="container">
                    <div className="section-title">
                        <div className="rich-text">
                            <h2 style={{color:"rgb(9, 10, 21)",fontSize:"40px",textAlign:"left"}}>{left}</h2>
                        </div>
                        <div className="sub-title-text">
                            <div className="rich-text" style={{width: "100%"}}>
                                <p style={{color:"rgb(10, 9, 21)",fontSize:"12px",textAlign:"left"}}>{rightTitle}</p>
                            </div>
                            <div className="rich-text" style={{width: "100%"}}>
                                <p style={{color:"rgb(82, 82, 90)",textAlign:"left",fontSize:"13px"}}>{rightDes}</p>

                            </div>

                        </div>

                    </div>
                    {isFeature && (
                        <div className="feature-card-list">
                            <div className="card">
                                <div className="icon">
                                    <div style={{
                                        position: "absolute",
                                        borderRadius: "inherit",
                                        inset: 0
                                    }}>
                                        <img
                                            src="https://framerusercontent.com/images/Rwi5kGBHf7EV429aEFELg31H27g.svg"/>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="rich-text">
                                        <p style={{
                                            color: "rgb(10, 9, 21)",
                                            fontSize: "20px",
                                            textAlign: "left"
                                        }}>Customer-Centric</p>

                                    </div>
                                    <div className="rich-text">
                                        <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>the
                                            award-winning calendar app with powerful features including</p>

                                    </div>

                                </div>

                            </div>
                            <div className="card">
                                <div className="icon">
                                    <div style={{
                                        position: "absolute",
                                        borderRadius: "inherit",
                                        inset: 0
                                    }}>
                                        <img
                                            src="https://framerusercontent.com/images/Q1Z8FhRWa3maYjYzCXOqj4LoMjE.svg"/>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="rich-text">
                                        <p style={{
                                            color: "rgb(10, 9, 21)",
                                            fontSize: "20px",
                                            textAlign: "left"
                                        }}>Reliability</p>

                                    </div>
                                    <div className="rich-text">
                                        <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>The
                                            top-rated calendar app with robust tools and features</p>

                                    </div>

                                </div>

                            </div>
                            <div className="card">
                                <div className="icon">
                                    <div style={{
                                        position: "absolute",
                                        borderRadius: "inherit",
                                        inset: 0
                                    }}>
                                        <img src="https://framerusercontent.com/images/hyfhSrmY5D9gFbsMCa0PhmPWu8.svg"/>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="rich-text">
                                        <p style={{
                                            color: "rgb(10, 9, 21)",
                                            fontSize: "20px",
                                            textAlign: "left"
                                        }}>Continuous Improvement

                                        </p>

                                    </div>
                                    <div className="rich-text">
                                        <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>The
                                            premium calendar app offering advanced features</p>

                                    </div>

                                </div>

                            </div>
                            <div className="card">
                                <div className="icon">
                                    <div style={{
                                        position: "absolute",
                                        borderRadius: "inherit",
                                        inset: 0
                                    }}>
                                        <img src="https://framerusercontent.com/images/2RIIFVxu1NpJhUXRB9IWtfcXDE.svg"/>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="rich-text">
                                        <p style={{
                                            color: "rgb(10, 9, 21)",
                                            fontSize: "20px",
                                            textAlign: "left"
                                        }}>Simplicity</p>

                                    </div>
                                    <div className="rich-text">
                                        <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>The
                                            leading calendar app with cutting-edge features and</p>

                                    </div>

                                </div>

                            </div>
                            <div className="card">
                                <div className="icon">
                                    <div style={{
                                        position: "absolute",
                                        borderRadius: "inherit",
                                        inset: 0
                                    }}>
                                        <img
                                            src="https://framerusercontent.com/images/eg8ZeUd9QmNQsbkcBKXubkIB1lw.svg"/>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="rich-text">
                                        <p style={{
                                            color: "rgb(10, 9, 21)",
                                            fontSize: "20px",
                                            textAlign: "left"
                                        }}>Collaboration</p>

                                    </div>
                                    <div className="rich-text">
                                        <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>The
                                            top calendar app boasting sophisticated tools and </p>

                                    </div>

                                </div>

                            </div>
                            <div className="card">
                                <div className="icon">
                                    <div style={{
                                        position: "absolute",
                                        borderRadius: "inherit",
                                        inset: 0
                                    }}>
                                        <img
                                            src="https://framerusercontent.com/images/lyXr3ndeYblB3P0bzkjFtbpjnNk.svg"/>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="rich-text">
                                        <p style={{
                                            color: "rgb(10, 9, 21)",
                                            fontSize: "20px",
                                            textAlign: "left"
                                        }}>Data-Driven</p>

                                    </div>
                                    <div className="rich-text">
                                        <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>The
                                            highly praised calendar app with dynamic features and</p>

                                    </div>

                                </div>

                            </div>
                            <div className="card">
                                <div className="icon">
                                    <div style={{
                                        position: "absolute",
                                        borderRadius: "inherit",
                                        inset: 0
                                    }}>
                                        <img src="https://framerusercontent.com/images/TANxYvB50Yd5dJCjp6E1DEwZGM.svg"/>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="rich-text">
                                        <p style={{
                                            color: "rgb(10, 9, 21)",
                                            fontSize: "20px",
                                            textAlign: "left"
                                        }}>Scalability for Growth</p>

                                    </div>
                                    <div className="rich-text">
                                        <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>
                                            The acclaimed calendar app with versatile features and benefits</p>

                                    </div>

                                </div>

                            </div>
                            <div className="card">
                                <div className="icon">
                                    <div style={{
                                        position: "absolute",
                                        borderRadius: "inherit",
                                        inset: 0
                                    }}>
                                        <img src="https://framerusercontent.com/images/he8pbnzIkk83ZT5U6v0Rpv9H8g.svg"/>


                                    </div>
                                </div>
                                <div className="content">
                                    <div className="rich-text">
                                        <p style={{
                                            color: "rgb(10, 9, 21)",
                                            fontSize: "20px",
                                            textAlign: "left"
                                        }}>Sustainability</p>

                                    </div>
                                    <div className="rich-text">
                                        <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>The
                                            best calendar app featuring enhanced tools and </p>

                                    </div>

                                </div>

                            </div>


                        </div>

                    )}

                </div>

            </div>
        </>
    )
}
export default IntroductionSection;