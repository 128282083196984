import React from 'react';
import CareerHome from "../CareerHome";
import Services from "../Services";
import ServiceMain from "../ServiceMain";
import IntroductionSection from "../IntroductionSection";
import Benefits from "../Benefits";
import Title from "../Title";
import Jobs from "../Jobs";
import FrequentQuections from "../FrequentQuections";
import Home2Des from "../Home2Des";

const Career = () => {
    return (
        <>
            <CareerHome/>
            {/*<ServiceMain img={"https://framerusercontent.com/images/6IXep0yQnItk2ItO2Ut2JZb5k7Q.png"}/>*/}

            <div className="service-section" style={{paddingBottom:0}}>
                <div className="container">
                    <div className="content">
                        <div className="image" style={{height:"400px"}}>
                            <div style={{
                                position: "absolute",
                                borderRadius: "inherit",
                                inset: 0,
                                height:"350px",
                                display: "grid",
                                gridTemplateColumns: "2fr 1fr 1fr"
                            }}>
                                <div style={{
                                    backgroundColor: "rgba(27,29,31,255)",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    paddingTop: "100px",
                                    paddingLeft: "50px",paddingRight:"50px"
                                }}>
                                    <div>
                                        <h1 style={{color:"white",fontFamily:"Times New Romans"}}>Explore.</h1>
                                        <div style={{width: "100px", height: "2px", backgroundColor: "white",marginTop:"20px"}}></div>
                                        <div style={{marginTop:"20px",color:"white",fontFamily:"Arial"}}>

                                                Your brand isn't just a name, its an experience. We explore design to shape the  way  people experience your brand,
                                            and influence what ist stands for in the hearts and minds of customers.
                                            </div>

                                    </div>
                                </div>
                                <div style={{
                                    backgroundColor: "rgba(28,51,63,255)",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    paddingTop: "100px",
                                    paddingLeft: "50px",paddingRight:"50px"
                                }}>
                                    <div>
                                        <h1 style={{color: "white", fontFamily: "Times New Romans"}}>Expand.</h1>
                                        <div style={{
                                            width: "100px",
                                            height: "2px",
                                            backgroundColor: "white",
                                            marginTop: "20px"
                                        }}></div>
                                        <div style={{marginTop: "20px", color: "white",fontFamily:"Arial"}}>

                                            We use creativity to help clients solve problems and expand  their business.
                                        </div>

                                    </div>
                                </div>
                                <div style={{
                                    backgroundColor: "rgba(66,85,99,255)",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    paddingTop: "100px",
                                    paddingLeft: "50px",paddingRight:"50px"
                                }}>
                                    <div>
                                        <h1 style={{color: "white", fontFamily: "Times New Romans"}}>Experience.</h1>
                                        <div style={{
                                            width: "100px",
                                            height: "2px",
                                            backgroundColor: "white",
                                            marginTop: "20px"
                                        }}></div>
                                        <div style={{marginTop: "20px", color: "white"}}>

                                           <button style={{borderRadius:"50px",padding:"10px",backgroundColor:"rgba(66,85,99,255)",border:"1px solid white",color:"white",fontFamily:"Arial"}}>CONTACT US</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>


            <IntroductionSection left={"All that you've committed to, all in one spot."}
                                 rightTitle={"ESTABLISHED IN 1997"}
                                 rightDes={"CalenTask uniquely combines task management, event scheduling, and contact organization into one seamless experience. Our integrated calendar feature transforms your tasks and events into a dynamic to-do list, helping you manage your time more efficiently."}/>

            {/*<Benefits/>*/}

            <Home2Des itemList={
                {
                    items: [
                        {
                            icon: "/Custom Web App Development.png",
                            iconText: "Logo Design",
                            description: "Crafting a unique and distinctive logo that embodies the core values and personality of your business, while creating a lasting impression.",
                        },
                        {
                            icon: "/Wordpress Development.png",
                            iconText: "Web Design",
                            description: "Developing user-friendly, aesthetically pleasing websites optimized for SEO, providing an engaging online experience for your visitors.",
                        },
                        {
                            icon: "/E-Commerce Development.png",
                            iconText: "Branding",
                            description: "Building powerful brand identities that form a deep connection with your target audience and differentiate you in the marketplace.",
                        },
                        {
                            icon: "/Business Process Automation.png",
                            iconText: "Graphic Design",
                            description: "Creating visually striking designs that captive and communicate your message effectively, to enhance your brand’s visual appeal.",
                        },
                        {
                            icon: "/Custom Business Portals.png",
                            iconText: "Social Media",
                            description: "Implementing dynamic social media strategies that elevate your online presence, engage your audience, and boost your brand’s digital footprint.",
                        },
                        {
                            icon: "/Consultancy & Discovery.png",
                            iconText: "Marketing",
                            description: "Delivering innovative marketing solutions that drive growth, increase brand awareness, and generate meaningful connections with your customers.",
                        }
                    ]
                }
            }
            />

            <FrequentQuections icon="/faq/workuspage.png"/>

            {/*<Title title2={"Open Positions"}/>*/}
            {/*<Jobs/>*/}

        </>
    )
}
export default Career;