//
// import React from 'react';
// import './counter.css'
//
// const Counter = () => {
//     return (
//         <>
//             <div className="counter-section">
//
//                 <div className="container">
//                     <div className="left-content">
//                         <div className="section-title">
//                             <div className="rich-text">
//                                 <h1 style={{color:"rgb(10, 9, 21)",fontSize:"48px",textAlign:"left"}}>More freedom to create the way you want</h1>
//
//                             </div>
//                             <div className="rich-text">
//                                 <p style={{color:"rgb(82, 82, 90)",fontSize:"16px",textAlign:"left"}}>CalenTask uniquely combines task management, event scheduling, and contact organization into one seamless experience. Our integrated calendar feature transforms your tasks and events into a dynamic to-do list.</p>
//
//                             </div>
//
//                         </div>
//                         <div className="counter-list">
//                             <div className="counter">
//                                 <div className="data">
//                                     <div style={{
//                                         flex: "0 0 auto",
//                                         height: "auto",
//                                         position: "relative",
//                                         width: "auto"
//                                     }}>
//                                         <div className="counting">320
//                                         </div>
//                                     </div>
//                                     <div className="rich-text">
//                                         <h1 style={{color: "rgb(249, 75, 37)", fontSize: "56px",fontWeight:"normal"}}>K</h1>
//                                     </div>
//
//
//                                 </div>
//                                 <div className="rich-text">
//                                     <p style={{color: "rgb(10, 9, 21)", fontSize: "12px",textAlign:"left"}}>FOLKS SO FAR</p>
//
//                                 </div>
//
//                             </div>
//                             <div className="counter">
//                                 <div className="data">
//                                     <div style={{
//                                         flex: "0 0 auto",
//                                         height: "auto",
//                                         position: "relative",
//                                         width: "auto"
//                                     }}>
//                                         <div className="counting">14
//                                         </div>
//                                     </div>
//
//
//
//                                 </div>
//                                 <div className="rich-text">
//                                     <p style={{color: "rgb(10, 9, 21)", fontSize: "12px",textAlign:"left"}}>COUNTRIES</p>
//
//                                 </div>
//
//                             </div>
//                             <div className="counter">
//                                 <div className="data">
//                                     <div style={{
//                                         flex: "0 0 auto",
//                                         height: "auto",
//                                         position: "relative",
//                                         width: "auto"
//                                     }}>
//                                         <div className="counting">100
//                                         </div>
//                                     </div>
//                                     <div className="rich-text">
//                                         <h1 style={{color: "rgb(249, 75, 37)", fontSize: "56px",fontWeight:"normal"}}>%</h1>
//                                     </div>
//
//
//                                 </div>
//                                 <div className="rich-text">
//                                     <p style={{color: "rgb(10, 9, 21)", fontSize: "12px",textAlign:"left"}}>REMOTE & HYBRID</p>
//
//                                 </div>
//
//                             </div>
//
//
//                         </div>
//
//                     </div>
//                     <div className="image">
//                         <div style={{
//                             position: "absolute",
//                             borderRadius: "inherit",
//                             inset: 0
//                         }}>
//                             <img src="https://framerusercontent.com/images/P1RCU6yle3iSta2PRlzReeTM.png"/>
//                         </div>
//
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }
// export default Counter;

import React, { useEffect, useRef, useState } from 'react';
import './counter.css';

const Counter = () => {
    const counterRef = useRef(null);
    const [counters, setCounters] = useState([0, 0, 0]);
    const [startCounting, setStartCounting] = useState(false);
    const intervals = useRef([]);

    // Values to count to
    const counts = [
        { end: 320, label: 'K', description: 'FOLKS SO FAR' },
        { end: 14, label: '', description: 'COUNTRIES' },
        { end: 100, label: '%', description: 'REMOTE & HYBRID' }
    ];

    // Function to handle counting
    const handleCounting = () => {
        // Clear any existing intervals to prevent multiple intervals
        intervals.current.forEach((interval) => clearInterval(interval));
        intervals.current = [];

        // Start counting up for each counter
        counts.forEach((count, index) => {
            let currentValue = 0;
            const increment = Math.ceil(count.end / 100);

            const interval = setInterval(() => {
                setCounters((prevCounters) => {
                    const newCounters = [...prevCounters];
                    if (newCounters[index] < count.end) {
                        newCounters[index] = Math.min(newCounters[index] + increment, count.end);
                    } else {
                        clearInterval(interval); // Stop counting when the target is reached
                    }
                    return newCounters;
                });
            }, 20);

            intervals.current[index] = interval;
        });
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Set startCounting to true to trigger the counting effect
                    setStartCounting(true);
                } else {
                    // Clear intervals if the section goes out of view
                    intervals.current.forEach((interval) => clearInterval(interval));
                    intervals.current = [];
                    setStartCounting(false); // Reset start counting when out of view
                }
            },
            { threshold: 0.5 } // Trigger when at least 50% of the section is visible
        );

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current);
            }
            // Clear any remaining intervals on component unmount
            intervals.current.forEach((interval) => clearInterval(interval));
        };
    }, []);

    useEffect(() => {
        if (startCounting) {
            // Reset counters to zero before starting the counting animation
            setCounters([0, 0, 0]);
            handleCounting();
        }
    }, [startCounting]); // Trigger this effect when startCounting changes

    return (
        <div className="counter-section" ref={counterRef}>
            <div className="container">
                <div className="left-content">
                    <div className="section-title">
                        <div className="rich-text">
                            <h1 style={{ color: "rgb(10, 9, 21)", fontSize: "38px", textAlign: "left" }}>
                                Your Trusted WordPress Website Design Partner
                            </h1>
                        </div>
                        <div className="rich-text">
                            <p style={{ color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left" }}>
                                At CodeLead (Pvt) Ltd, we're passionate about helping small businesses succeed online. Our team of WordPress experts creates custom websites that not only look great but are also search engine-friendly and fully optimized to boost your online visibility.
                                We make the process simple and stress-free. From building your site to ongoing maintenance and support, we've got you covered. Take a look at our portfolio to see what we've done for other businesses like yours. Curious about how we can bring your vision to life? Let us create a free mockup for you and show you why so many clients love working with us!
                            </p>
                        </div>
                    </div>
                    {/*<div className="counter-list">*/}
                    {/*    {counts.map((count, index) => (*/}
                    {/*        <div className="counter" key={index}>*/}
                    {/*            <div className="data">*/}
                    {/*                <div className="counting">*/}
                    {/*                    {counters[index]}*/}
                    {/*                </div>*/}
                    {/*                {count.label && (*/}
                    {/*                    <div className="rich-text">*/}
                    {/*                        <h1 style={{ color: "rgb(249, 75, 37)", fontSize: "56px", fontWeight: "normal" }}>*/}
                    {/*                            {count.label}*/}
                    {/*                        </h1>*/}
                    {/*                    </div>*/}
                    {/*                )}*/}
                    {/*            </div>*/}
                    {/*            <div className="rich-text">*/}
                    {/*                <p style={{ color: "rgb(10, 9, 21)", fontSize: "12px", textAlign: "left" }}>{count.description}</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    ))}*/}
                    {/*</div>*/}
                </div>
                <div className="image">
                    <div style={{
                        position: "absolute",
                        borderRadius: "inherit",
                        inset: 0,
                        display: "grid",
                        alignItems: "center",
                    }}>
                        <img src="/cover-pic.svg" alt="Background" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Counter;
